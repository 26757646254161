const asc = (a: any, b: any, func?: (x: any) => any) => {
  return normal(a, b, func);
};
const desc = (a: any, b: any, func?: (x: any) => any) => {
  return normal(b, a, func);
};
const normal = (a: any, b: any, func?: (x: any) => any) => {
  if (func) {
    a = func(a);
    b = func(b);
  }
  const aNumber = Number(a);
  const bNumber = Number(b);

  if (!Number.isNaN(aNumber)) a = aNumber;
  if (!Number.isNaN(bNumber)) b = bNumber;

  a = a ?? 0;
  b = b ?? 0;

  return a > b ? 1 : a < b ? -1 : 0;
};

export default {
  normal,
  asc,
  desc,
};
